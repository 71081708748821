import React from "react";
import "./Banner.css";
import { Link } from "react-router-dom";
import { useAppContext } from "../../AppContext";

function Banner() {
  const { user } = useAppContext();
  return (
    <div className="banner" id="banner">
      <div className="leftSide" data-aos="fade-left">
        <span className="badgeText">Essiloss Trade Academy</span>
        <h1>Strateji ile Birlikte Sıfırdan Fon Yönetimine</h1>
        <p>
          Videoları izleyin, öğrenin, trade becerilerinizi profesyonel seviyeye
          çıkarın.
        </p>
        {user ? (
          <Link to="/dashboard/home" className="btn">
            Dashboard
          </Link>
        ) : (
          <Link to="/signUp" className="btn">
            Kayıt Ol
          </Link>
        )}
      </div>
      <div className="rightSide" data-aos="fade-right">
        <div className="bannerImages">
          <img src="/assets/banner-photo-1.jpg" alt="banner-photo" />
          <img src="/assets/banner-photo-2.jpg" alt="banner-photo" />
        </div>
      </div>
    </div>
  );
}

export default Banner;
